function init_header() {
  // Mobile hambuger
  $('.js-toggle-mobile-menu').on('click', function() {
    var $body = $('body');
    var $hamburger = $('.js-hamburger');

    $body.toggleClass('mobile-menu-open');
    $hamburger.toggleClass('is-active');
  });

  // Desktop handlers
  if($(window).width() >= 1200) {
    $('li.menu-item-has-children').mouseenter(function() {
        var $el = $(this);
        var $dropdown = $el.children('.sub-menu');

        if($dropdown.length) {
          $el.children('a').css('background-color', '#fff');
          $el.children('a').css('color', '#000');
          $dropdown.css('opacity', '1');
          $dropdown.css('pointer-events', 'auto');
        }
    });
    $('li.menu-item-has-children').mouseleave(function() {
      var $el = $(this);
      var $dropdown = $el.children('.sub-menu');

      if($dropdown.length) {
        $el.children('a').css('background-color', 'transparent');
        $el.children('a').css('color', '#fff');
        $dropdown.css('opacity', '0');
        $dropdown.css('pointer-events', 'none');
      }
    });
  }

  // Scroll to top
  // Attach waypoint
  $scroll_to_top = $('.cpx-button.scroll-to-top');

  if($scroll_to_top.length) {
    evaluate_scroll_to_top_button();

    var scrollTimeout;
    var throttle = 100;
    $(window).scroll(function() {
      if (!scrollTimeout) {
        scrollTimeout = setTimeout(function () {
            evaluate_scroll_to_top_button();
            scrollTimeout = null;
        }, throttle);
      }
    });



    $scroll_to_top.on('click', function() {
      // Slide the case sliders to top
      $case_slider = $('.js-case-slider');

      if($case_slider.length) {
        $case_slider.slick('slickGoTo', 0);
      }

      smooth_scroll(0);
    });
  }
}

function evaluate_scroll_to_top_button() {
  if ($(window).scrollTop() > 300) {
    $scroll_to_top.fadeIn(200);
  } else {
    $scroll_to_top.fadeOut(200);
  }
}
