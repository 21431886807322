function init_ankerpunt() {
    $ankerpunten = $('.js-ankerpunt');

    if($ankerpunten.length) {
        $ankerpunten.on('click', function() {
            $clicked = $(this);

            ankerpunt_doel = $clicked.attr('data-ankerpunt-doel');

            // If this is a direction we're going to
            if(ankerpunt_doel == 'omhoog') {
                $target = $('body');
            }
            else {
                $target = $('#'+ankerpunt_doel);
            }

            // If this should link to a slide in the cases
            if(ankerpunt_doel.includes('case-')) {
                $case_slider = $('.js-case-slider');

                // Slider exists
                if($case_slider.length) {
                    // Go to first slide
                    if(ankerpunt_doel == 'case-premium-pos') {
                        $case_slider.slick('slickGoTo', 0);
                        smooth_scroll($case_slider.offset().top);
                    }

                    // Determine the first exclusive slide, and go to that index
                    else if(ankerpunt_doel == 'case-exclusive-pos') {
                        show_exclusive_pos_slide($case_slider);
                    }
                }
            }

            // If this is an element we should scroll to
            else if($target.length) {
                smooth_scroll($target.offset().top);
            }

            // If not found
            else {
                console.warn('Target ankerpunt not found.');
            }

        });
    }
}

jQuery(document).ready(function($) {
    init_ankerpunt();
});